<template>
	<div class="w-full d-md-flex">
		<div class="mr-md-4">
			<div class="settings-menu-navigation" :style="`background-color: white; width: ${width}px;`">
				<div class="custom-menu-style py-3">
					<div v-for="(item, itemI) in menuList" :key="item + itemI" class="relative">
						<div class="menu-cat" style="font-weight: bold;">{{ item.label }}</div>
						<a-tooltip v-if="item.info" overlayClassName="change-tooltip-color" class="absolute"
							style="left: 128px; top: 4px;">
							<template slot="title">
								{{ item.info }}
							</template>
							<a-icon type="question-circle"
								style="line-height: 22.5px; margin-top: 5px; color: var(--orange);" />
						</a-tooltip>
						<div @click="handleClick({ key: child.id })" class="menu-item"
							:class="activeTab == child.id ? 'active' : ''" v-for="(child, childI) in item.children"
							:key="child + childI">

							{{ child.label }}
							<a-tooltip v-if="child.info" overlayClassName="change-tooltip-color">
								<template slot="title">
									{{ child.info }}
								</template>
								<a-icon type="question-circle" style="font-size: 14px" />
							</a-tooltip>
						</div>
						<hr v-if="itemI != menuList.length - 1" />
					</div>
				</div>
			</div>
		</div>

		<div class="hide-scrollbar mt-4 mt-md-0" style="flex: 1; overflow: hidden;">
			<div>
				<slot />
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		activeTab: {
			default: 0,
			type: [Number, String]
		},
		menuList: {
			default: () => [],
			type: Array
		},
		width: {
			default: '225',
			type: String
		}
	},

	methods: {
		handleClick(e) {
			this.$emit('menuClick', e.key)
		},
	},
}
</script>
<style lang="scss" module></style>
<style lang="scss" scoped>
.menu-cat {
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.menu-item {
	cursor: pointer;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	transition: color 0.3s ease-in-out;
}

.menu-item:hover {
	color: #afafaf;
	transition: color 0.3s ease-in-out;
}

.menu-item.active {
	color: var(--orange);
	transition: color 0.3s ease-in-out;
}
</style>


<style lang="scss">
.settings-menu-navigation {
	@media screen and (max-width: 768px) {
		width: 100% !important;
	}
}
.custom-menu-style .ant-menu-item-selected {
	margin: 0 !important;
	background-color: #FAF9F9 !important;
	color: var(--orange);
}

.custom-menu-style .ant-menu-item-selected svg {
	color: var(--orange);
}

.custom-menu-style .ant-menu-item {
	margin: 0 !important;
	height: 60px !important;
	display: flex;
	align-items: center;
}

.custom-menu-style .ant-menu-submenu-title {
	height: 60px !important;
	display: flex;
	align-items: center;
	color: #000 !important;
}

.custom-menu-style .ant-menu-submenu-selected {
	color: #000 !important;
}

.custom-menu-style .ant-menu-submenu-inline {
	border-bottom: 1px solid #E4E7EB
}

.custom-menu-style .ant-menu-item::after {
	border-right: none !important;
}
</style>
